import { lazy, Suspense, useEffect, useState } from 'react';
import React from "react";
import { Home } from "./Routes/home";
//import { InvioMAD } from "./InvioMAD";
//import { Prezzi } from "./Prezzi";
//import { Contatti } from "./Contatti";
//import { ModelloLetteraMAD } from './ModelloLetteraMAD'
//import { TerminieCondizioni } from './TerminieCondizioni'
//import { PrivacyPolicy } from './PrivacyPolicy'
import whatsapp from "./img/whatsapp.webp"
//import telegram from "./img/telegram.webp"
import fb from "./img/fb.webp"
import yt from "./img/yt.webp"
//import ig from "./img/Ig.webp"
import mail from "./img/mail.webp"
//import aws from "./img/PB_AWS.webp"
import { AsyncImage } from "./Components/AsyncImage"



/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css'; // Remove if nothing is visible
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';


import { IonApp, IonContent, IonPage, IonSpinner, setupIonicReact } from '@ionic/react';




import {
    BrowserRouter,
    Routes,
    Route,
    Link,
} from 'react-router-dom'

import { ComponentMenu } from './Components/Menu';
import { Menu } from './Components/Menu';
import { Skeleton } from 'antd';
import { createTheme, ThemeProvider } from '@mui/material';







//import { AvvenutoInvio } from "./AvvenutoInvio";


const AvvenutoInvio = lazy(() =>
    import('./Routes/AvvenutoInvio')
        .then(({ AvvenutoInvio }) => ({ default: AvvenutoInvio }))
);

const InvioMAD = lazy(() =>
    import('./Routes/InvioMAD')
        .then(({ InvioMAD }) => ({ default: InvioMAD }))
);

const TerminieCondizioni = lazy(() =>
    import('./Routes/TerminieCondizioni')
        .then(({ TerminieCondizioni }) => ({ default: TerminieCondizioni }))
);

const Prezzi = lazy(() =>
    import('./Routes/Prezzi')
        .then(({ Prezzi }) => ({ default: Prezzi }))
);

const Contatti = lazy(() =>
    import('./Routes/Contatti')
        .then(({ Contatti }) => ({ default: Contatti }))
);

const PrivacyPolicy = lazy(() =>
    import('./Routes/PrivacyPolicy')
        .then(({ PrivacyPolicy }) => ({ default: PrivacyPolicy }))
);

const Informazioni = lazy(() =>
    import('./Articoli/Informazioni')
        .then(({ Informazioni }) => ({ default: Informazioni }))
);

const NotFound = lazy(() =>
    import('./Routes/NotFound')
        .then(({ NotFound }) => ({ default: NotFound }))
);

const MADOnline = lazy(() =>
    import('./Articoli/MAD-Online')
        .then(({ MADOnline }) => ({ default: MADOnline }))
);

const Registrazione = lazy(() =>
    import('./Routes/Registrazione')
        .then(({ Registrazione }) => ({ default: Registrazione }))
);

const PagamentoInterpelli = lazy(() =>
    import('./Routes/PagamentoInterpelli')
        .then(({ PagamentoInterpelli }) => ({ default: PagamentoInterpelli }))
);

const Abbonamento = lazy(() =>
    import('./Routes/Abbonamento')
        .then(({ Abbonamento }) => ({ default: Abbonamento }))
);

const Interpelli = lazy(() =>
    import('./Routes/Interpelli')
        .then(({ Interpelli }) => ({ default: Interpelli }))
);


const RecuperaPassword = lazy(() =>
    import('./Routes/RecuperaPassword')
        .then(({ RecuperaPassword }) => ({ default: RecuperaPassword }))
);

const ResetPermissions = lazy(() =>
    import('./Routes/ResetPermissions')
        .then(({ ResetPermissions }) => ({ default: ResetPermissions }))
);

const Articolo = lazy(() =>
    import('./Routes/Articolo')
        .then(({ Articolo }) => ({ default: Articolo }))
);

const Notizie = lazy(() =>
    import('./Routes/Notizie')
        .then(({ Notizie }) => ({ default: Notizie }))
);

const Candidature = lazy(() =>
    import('./Routes/Candidature')
        .then(({ Candidature }) => ({ default: Candidature }))
);

const Paritarie = lazy(() =>
    import('./Routes/Paritarie')
        .then(({ Paritarie }) => ({ default: Paritarie }))
);

const InvioMAD_Pagato = lazy(() =>
    import('./Routes/InvioMAD_Pagato')
        .then(({ InvioMAD_Pagato }) => ({ default: InvioMAD_Pagato }))
);

const InvioParitarie_Pagato = lazy(() =>
    import('./Routes/InvioParitarie_Pagato')
        .then(({ InvioParitarie_Pagato }) => ({ default: InvioParitarie_Pagato }))
);









setupIonicReact()

//referrer={referrer}
export const Master = () => {
    //export class Master extends React.Component {
    //render() {
    const renderLoader = () => <Skeleton active style={{ width: "90%", margin: "2rem auto" }} />;
    //var referrer = window.frames.top?.document.referrer 

    const [width_, setwidth_] = useState<any>(window.visualViewport?.width)

    const theme = createTheme({
        palette: {
            primary: {
                main: '#ffffff'
            },
            secondary: {
                main: '#03183a'
            }
        }
    });
    /*
        useEffect(() => {
            //if (localStorage.getItem('UserID') == '5285--2024-02-28T20:24:53') {
            var script = document.createElement('script')
            script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5596508011220690"
            script.crossOrigin = "anonymous"
            window.document.body.appendChild(script);
            //}
        }, [])*/


    return (
        <>
           
            {/*<ThemeProvider theme={theme}>*/}

                <BrowserRouter>
                    <Menu Width_={width_}></Menu>
                    <IonPage lang="it" id='main-content'>

                        <IonContent>
                        <div id='top'></div>
                            <ComponentMenu Width_={width_}></ComponentMenu>
                            <div id="BodyFittizio">



                                <Routes>
                                    <Route path='' element={<Home width_={width_}></Home>}></Route>
                                    <Route path='Contatti' element={<Suspense fallback={renderLoader()}><Contatti /></Suspense>}></Route>
                                    <Route path='Prezzi' element={<Suspense fallback={renderLoader()}><Prezzi /></Suspense>}></Route>
                                    <Route path='Candidature' element={<Suspense fallback={renderLoader()}><Candidature /></Suspense>}></Route>
                                    <Route path='InvioMAD' element={<Suspense fallback={renderLoader()}><InvioMAD /></Suspense>}></Route>
                                    <Route path='Paritarie' element={<Suspense fallback={renderLoader()}><Paritarie /></Suspense>}></Route>
                                    <Route path='TerminieCondizioni' element={<Suspense fallback={renderLoader()}><TerminieCondizioni /></Suspense>}></Route>
                                    <Route path='PrivacyPolicy' element={<Suspense fallback={renderLoader()}><PrivacyPolicy /></Suspense>}></Route>
                                    <Route path='InvioMAD/AvvenutoInvio/*' element={<Suspense fallback={renderLoader()}><AvvenutoInvio /></Suspense>}></Route>
                                    <Route path='mad-online-quali-sono-i-vantaggi' element={<Suspense fallback={renderLoader()}><MADOnline width_={width_}></MADOnline></Suspense>}></Route>
                                    <Route path='messa-a-disposizione-mad-a-cosa-serve-come-funziona-punteggio-dove-quando-requisiti' element={<Suspense fallback={renderLoader()}><Informazioni /></Suspense>}></Route>
                                    <Route path='Interpelli' element={<Suspense fallback={renderLoader()}><Interpelli /></Suspense>}></Route>
                                    <Route path='Registrazione' element={<Suspense fallback={renderLoader()}><Registrazione /></Suspense>}></Route>
                                    <Route path='Pagamento' element={<Suspense fallback={renderLoader()}><PagamentoInterpelli /></Suspense>}></Route>
                                    <Route path='Abbonamento' element={<Suspense fallback={renderLoader()}><Abbonamento /></Suspense>}></Route>
                                    <Route path='RecuperaPassword' element={<Suspense fallback={renderLoader()}><RecuperaPassword /></Suspense>}></Route>
                                    {/*<Route path='ResetPermissions' element={<Suspense fallback={renderLoader()}><ResetPermissions /></Suspense>}></Route>*/}
                                    <Route path='pagato' element={<Suspense fallback={renderLoader()}><InvioMAD_Pagato /></Suspense>}></Route>
                                    <Route path='pagato-invio-immediato' element={<Suspense fallback={renderLoader()}><InvioParitarie_Pagato /></Suspense>}></Route>
                                   
                                    
                                    <Route path='Articolo/*' element={<Suspense fallback={renderLoader()}><Articolo /></Suspense>}></Route>
                                    <Route path='Notizie' element={<Suspense fallback={renderLoader()}><Notizie Width_={width_} setWidth_={setwidth_} /></Suspense>}></Route>



                                    




                                    <Route path='*' element={<Suspense fallback={renderLoader()}><NotFound /></Suspense>}></Route>
                                </Routes>

                            </div>
                            <footer>
                                <div id="div-Footer">

                                    <Link className="Link" to={"/TerminieCondizioni"} onClick={() => { document.documentElement.scrollTop = 0 }} style={{ fontSize: "10px", fontWeight: "500" }}>Condizioni d'uso</Link>
                                    <Link className="Link" to={"/PrivacyPolicy"} onClick={() => { document.documentElement.scrollTop = 0 }} style={{ fontSize: "10px", fontWeight: "500" }}>Privacy</Link>
                                    <Link className="Link" to={"/Contatti"} onClick={() => { document.documentElement.scrollTop = 0 }} style={{ fontSize: "10px", fontWeight: "500" }}>Assistenza</Link>

                                </div>
                                <div id="SovraIntestazione">
                                    <div id="ContattiCell">
                                        <label style={{ color: "white", fontSize: "10px", fontWeight: "500" }}>P.IVA: 04709340618</label>
                                    </div>
                                    <div id="ContattiMail">
                                        <AsyncImage src={mail} className='mail' alt="mail" />
                                        <label style={{ color: "white", fontSize: "10px", fontWeight: "500" }}>assistenza.easymad@gmail.com</label>
                                    </div>
                                </div>
                            </footer>
                        </IonContent>
                    </IonPage>
                </BrowserRouter >
           {/* </ThemeProvider>*/}

        </ >
    )
    //}
}

/*
                           <!-- Google tag (gtag.js)
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11047423570"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'AW-11047423570');
</script>
 -->
 

<!--
    <meta http-equiv="Content-Security-Policy"
    content="script-src 'strict-dynamic' 'unsafe-inline' https:; script-src-elem  'self' https://www.paypal.com/ 'sha256-b+mf6EIMFYxuAIdk6/2IF09zTUsJrlW6qZaw4opG6QU=' ;
    object-src 'none';
    base-uri 'none'; "/>
-->
*/